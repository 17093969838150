import React, { useState, useEffect } from 'react';
import { ArrowRight, Play, MapPin, Mail, Phone, Menu, X } from 'lucide-react';

const LandingPage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="font-sans">
      {/* Fixed Navigation */}
      <nav className={`fixed w-full z-10 transition-all duration-300 ${isScrolled ? 'bg-white shadow-md' : 'bg-transparent'}`}>
        <div className="container mx-auto px-6 py-3">
          <div className="flex justify-between items-center">
            <a href="#" className={`text-2xl font-bold ${isScrolled ? 'text-blue-600' : 'text-white'}`}>YELIED</a>
            <div className="hidden md:flex space-x-6">
              <a href="#home" className={`hover:text-blue-600 transition duration-300 ${isScrolled ? 'text-gray-600' : 'text-white'}`}>Home</a>
              <a href="#about" className={`hover:text-blue-600 transition duration-300 ${isScrolled ? 'text-gray-600' : 'text-white'}`}>About</a>
              <a href="#services" className={`hover:text-blue-600 transition duration-300 ${isScrolled ? 'text-gray-600' : 'text-white'}`}>Services</a>
              <a href="#portfolio" className={`hover:text-blue-600 transition duration-300 ${isScrolled ? 'text-gray-600' : 'text-white'}`}>Portfolio</a>
              <a href="#contact" className={`hover:text-blue-600 transition duration-300 ${isScrolled ? 'text-gray-600' : 'text-white'}`}>Contact</a>
            </div>
            <button className="hidden md:block bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300">
              Get Started
            </button>
            <button 
              className="md:hidden focus:outline-none" 
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              {isMenuOpen ? <X size={24} className={isScrolled ? 'text-gray-800' : 'text-white'} /> : <Menu size={24} className={isScrolled ? 'text-gray-800' : 'text-white'} />}
            </button>
          </div>
        </div>
        {/* Mobile menu */}
        {isMenuOpen && (
          <div className="md:hidden bg-white">
            <a href="#home" className="block py-2 px-4 text-sm hover:bg-gray-200">Home</a>
            <a href="#about" className="block py-2 px-4 text-sm hover:bg-gray-200">About</a>
            <a href="#services" className="block py-2 px-4 text-sm hover:bg-gray-200">Services</a>
            <a href="#portfolio" className="block py-2 px-4 text-sm hover:bg-gray-200">Portfolio</a>
            <a href="#contact" className="block py-2 px-4 text-sm hover:bg-gray-200">Contact</a>
            <a href="#" className="block py-2 px-4 text-sm bg-blue-500 text-white">Get Started</a>
          </div>
        )}
      </nav>

      {/* Hero Section */}
      <header id="home" className="bg-gradient-to-r from-blue-500 to-purple-600 text-white py-32 md:py-64">
        <div className="container mx-auto px-6 text-center">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">
            Better Solutions For Your Business
          </h1>
          <p className="text-xl md:text-2xl mb-8">
            We are team of talented designers making AI Powered business solutions
          </p>
          <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4">
            <button className="bg-white text-blue-600 px-6 py-3 rounded-md hover:bg-gray-100 transition duration-300 flex items-center justify-center">
              Get Started <ArrowRight className="ml-2" />
            </button>
            <button className="bg-transparent border-2 border-white text-white px-6 py-3 rounded-md hover:bg-white hover:text-blue-600 transition duration-300 flex items-center justify-center">
              Watch Video <Play className="ml-2" />
            </button>
          </div>
        </div>
      </header>

      {/* About Section */}
      <section id="about" className="py-16 bg-gray-100">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">About Us</h2>
          <p className="text-gray-600 text-center mb-8 max-w-2xl mx-auto">
            At Yelied, we're driven by a passion for excellence, innovation, and customer satisfaction. 
            With years of experience in the industry, our team of experts is committed to delivering 
            results that exceed expectations.
          </p>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4">Cloud solutions for your business</h3>
              <p className="text-gray-600">Leverage the power of cloud computing for your business needs.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4">Desktop/Mobile solutions</h3>
              <p className="text-gray-600">Seamless experiences across all devices and platforms.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4">AI Powered solutions</h3>
              <p className="text-gray-600">Harness the potential of artificial intelligence for your business.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section id="services" className="py-16">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">Our Services</h2>
          <div className="grid md:grid-cols-4 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4">Web Solutions</h3>
              <p className="text-gray-600">We are providing cloud-based web solutions</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4">AI Solutions</h3>
              <p className="text-gray-600">Innovative AI based solutions.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4">Database Solutions</h3>
              <p className="text-gray-600">We provide database solutions.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4">Mobile Apps</h3>
              <p className="text-gray-600">Experts in Mobile Applications</p>
            </div>
          </div>
        </div>
      </section>

      {/* Portfolio Section */}
      <section id="portfolio" className="py-16 bg-gray-100">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">Our Portfolio</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {/* Add portfolio items here */}
            <div className="bg-white p-6 rounded-lg shadow-md">
              <img src={`${process.env.PUBLIC_URL}/images/ims.png`} alt="Project 1" className="w-full h-48 object-cover rounded mb-4" />
              <h3 className="text-xl font-semibold mb-2">Invendory Management System</h3>
              <p className="text-gray-600">This allows to summaris the day and get the sale summary recorded in cloud.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <img src={`${process.env.PUBLIC_URL}/images/VAT.png`} alt="Project 2" className="w-full h-48 object-cover rounded mb-4" />
              <h3 className="text-xl font-semibold mb-2">VAT Calculator</h3>
              <p className="text-gray-600">Allows you to find your VAT Payable from the purchasing and sale</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <img src={`${process.env.PUBLIC_URL}/images/sts.png`} alt="Project 3" className="w-full h-48 object-cover rounded mb-4" />
              <h3 className="text-xl font-semibold mb-2">Salesman Tracking System</h3>
              <p className="text-gray-600">Track your purcasing and salesman activities efffectively</p>
            </div>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="bg-blue-600 text-white py-16">
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-3xl font-bold mb-4">Ready to Transform Your Business?</h2>
          <p className="mb-8">
            Let's innovate together! Contact us today and step into the future of business excellence.
          </p>
          <button className="bg-white text-blue-600 px-6 py-3 rounded-md hover:bg-gray-100 transition duration-300">
            Contact Us
          </button>
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="py-16">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">Contact Us</h2>
          <div className="grid md:grid-cols-2 gap-8">
            <div>
              <form>
                <input type="text" placeholder="Your Name" className="w-full mb-4 p-2 border rounded" />
                <input type="email" placeholder="Your Email" className="w-full mb-4 p-2 border rounded" />
                <input type="text" placeholder="Subject" className="w-full mb-4 p-2 border rounded" />
                <textarea placeholder="Message" className="w-full mb-4 p-2 border rounded" rows="4"></textarea>
                <button className="bg-blue-500 text-white px-6 py-3 rounded-md hover:bg-blue-600 transition duration-300">
                  Send Message
                </button>
              </form>
            </div>
            <div>
              <div className="flex items-center mb-4">
                <MapPin className="mr-2 text-blue-500" />
                <p>30/3, Wela Road, Maharagama, Colombo, LK</p>
              </div>
              <div className="flex items-center mb-4">
                <Mail className="mr-2 text-blue-500" />
                <p>yeliedsolutions@gmail.com</p>
              </div>
              <div className="flex items-center mb-4">
                <Phone className="mr-2 text-blue-500" />
                <p>+947 1568 1568</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-800 text-white py-8">
        <div className="container mx-auto px-6">
          <div className="grid md:grid-cols-4 gap-8">
            <div>
              <h3 className="text-xl font-bold mb-4">YELIED</h3>
              <p>Your trusted partner in innovative solutions</p>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-4">Useful Links</h3>
              <ul>
                <li><a href="#" className="hover:text-gray-300 transition duration-300">Home</a></li>
                <li><a href="#" className="hover:text-gray-300 transition duration-300">About us</a></li>
                <li><a href="#" className="hover:text-gray-300 transition duration-300">Services</a></li>
                <li><a href="#" className="hover:text-gray-300 transition duration-300">Terms of service</a></li>
                <li><a href="#" className="hover:text-gray-300 transition duration-300">Privacy policy</a></li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-4">Our Services</h3>
              <ul>
                <li>Web Design</li>
                <li>Web Development</li>
                <li>Mobile Applications</li>
                <li>Cloud Applications</li>
                <li>AI Solutions</li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-4">Join Our Newsletter</h3>
              <p className="mb-4">Stay informed with the latest industry trends, news, and insights from our team of experts.</p>
              <form>
                <input type="email" placeholder="Your email" className="w-full p-2 mb-2 text-gray-800 rounded" />
                <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300 w-full">Subscribe</button>
              </form>
            </div>
          </div>
          <div className="border-t border-gray-700 mt-8 pt-8 text-center">
            <p>&copy; 2024 Yelied. All Rights Reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;